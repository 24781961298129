.Game {
  text-align: center;
}

.Game-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.Game-info {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Game-stats {
  display: flex;
  justify-content: space-around;
  width: 100%;
  margin-top: 10px;
}

.Game-stats span {
  margin: 0 10px;
  font-size: 1.5em;
}
